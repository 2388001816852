$exin-green: #BDCD00;
$exin-lightblue: #0289CD;
$exin-blue: #015794;
$exin-darkblue: #003255;

$exin-light-alt: #BCC4F3;
$exin-light-alt2: #B0BAF3;
$exin-dark-alt: #27448f;

/* customize variables */
$primary: $exin-light-alt;
$secondary: $exin-dark-alt;

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

// customize classes
.nav-tabs {
  .nav-link {
    color: #0289CD;
  }
  .nav-link.active {
    color: #003255;
  }
}

.container {
  max-width: 1750px;
}