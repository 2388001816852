.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: 100%;
}

.App-scrollable {
  flex: 1 1 auto;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.App-main {
  flex: 1 0 auto;
}

.App-footer {
  flex: 0 1 0;
  width: 100%;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toast-container {
  padding: 1rem;
  z-index: 2000;
}

.container-fullwidth {
  max-width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.fill-height {
  flex: 1 1 auto;
}

.flex-fill-limit {
  flex: 1 1 0px;
  overflow: auto;
}

.sticky-bottom {
  position: sticky;
  bottom: 0px;
  z-index: 1020;
}

.sticky-left {
  position: sticky;
  left: 0px;
  z-index: 1000;
}

.multiline-text {
  white-space: pre-wrap;
}

.cell-input {
  min-width: 5em !important;
}

.readonly-light input:read-only {
  background: aliceblue !important;
}

.readonly-light .input-group-text {
  background: aliceblue !important;
}

.landing-overlay {
  background: #0032559a;
  backdrop-filter: blur(7px);
}

.workspace-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2.5em;
  overflow-y: auto;
  padding-top: 1em;
  padding-bottom: 1em;
}

.workspace-card-placeholder {
  width: 24em;
  height: 15em;
}

.workspace-card {
  width: 24em;
  height: 15em;
}

.workspace-card:not([disabled]) {
  cursor: pointer;
}

.workspace-card[disabled] {
  cursor: wait;
}

.workspace-card:hover:not([disabled]) {
  box-shadow: 0 0 1em #0289CD;
}

.workspace-card:focus:not([disabled]) {
  box-shadow: 0 0 1em #0289CD;
}

.permissions-row:hover {
  background: #00000010;
}